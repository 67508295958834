import { DateTime } from 'luxon'

interface ModelData {
  [key: string]: any
}

interface Base {
  id: number
  createdAt?: DateTime
  updatedAt?: DateTime
  toJSON?(): any
}

function dataToBase(data: ModelData): Base {
  const base: Base = {
    id: data.id,
    createdAt: data.createdAt ? DateTime.fromISO(data.createdAt) : undefined,
    updatedAt: data.updatedAt ? DateTime.fromISO(data.updatedAt) : undefined,
  }
  return base
}

export { dataToBase }
export type { ModelData, Base }
