import { appendResponseHeader } from 'h3'
import { FetchError, type FetchResponse } from 'ofetch'
import { modelMapper, dataToError, type ModelData } from '~/models/index'
import { type TokenPair } from '~/models/user'
import { type Plan } from '~/models/order'
import { versionMapper, type Version } from '~/models/version'
import { type SiteStats } from '~/models/site'
import type { NuxtError } from '#app'
import * as yup from 'yup'

const TOKEN_ERROR_CODES = ['token_expired', 'token_invalid', 'token_required']
const APP_HEADER = { 'X-App-Type': 'pz-site' }

interface FetchOptions extends RequestInit {
  body?: any
  query?: any
  auth?: boolean
}

const useAPIClient = () => {
  const authCookie = useCookie('pz-site-auth')
  const userState = useUserState()
  const nuxtApp = useNuxtApp()
  const requestURL = useRequestURL()
  const i18n = useI18n()
  const { region } = useRegion()
  const showCaptcha = ref(false)
  const captchaPayload = ref('')
  
  const baseUrl = `${requestURL.protocol}//${requestURL.host}/api`

  function isAccessExpired(tp: TokenPair) {
    return (tp.accessExpiry <= (Date.now() + 2 * 1000))
  }

  function isRefreshExpired(tp: TokenPair) {
    return (tp.refreshExpiry <= (Date.now() + 0 * 1000))
  }

  async function refreshToken() {
    let data: any
    const tokenPair = userState.value.tokenPair
    if (!tokenPair) return

    // Skip refresh if access token is not expired
    if (!isAccessExpired(tokenPair)) return

    // If refresh token is expired, invalidate
    if (isRefreshExpired(tokenPair)) {
      invalidateAuth()
      throw new Error('refresh token is expired')
    }
    console.log('Refreshing access token...')

    try {
      if (process.server) {
        data = await serverRefreshToken(tokenPair)
      } else {
        data = await clientRefreshToken(tokenPair)
      }
    } catch (err) {
      console.log('[APIClient] error occurred in refresh token:')
      console.log((err as Error).message)
      invalidateAuth()
      throw err
    }

    userState.value.tokenPair = modelMapper.TokenPair(data)
  }

  async function serverRefreshToken(tp: TokenPair) {
    try {
      const res = await $fetch.raw(`${baseUrl}/users/refresh_token`, {
        method: 'POST',
        headers: { ...APP_HEADER, 'X-API-Region': region },
        body: { refreshToken: tp.refreshToken }
      })

      appendCookies(res)

      return res._data
    } catch (err: any) {
      const res = err.response
      if (res) appendCookies(res)
      throw err
    }
  }

  async function clientRefreshToken(tp: TokenPair) {
    const data = await $fetch(`${baseUrl}/users/refresh_token`, {
      method: 'POST',
      headers: { ...APP_HEADER, 'X-API-Region': region },
      body: { refreshToken: tp.refreshToken }
    })
    return data
  }

  function authHeaders() {
    const token = userState.value.tokenPair?.accessToken
    if (token) return { 'Authorization': `Bearer ${token}` }
    return {}
  }

  function invalidateAuth() {
    userState.value = {}
    authCookie.value = undefined
  }

  function appendCookies<T>(res: FetchResponse<T>) {
    if (!process.server) return

    const cookies = res.headers.get('Set-Cookie')
    if (!cookies) return

    const event = nuxtApp.ssrContext?.event
    if (event) {
      appendResponseHeader(event, 'Set-Cookie', cookies)
    }
  }

  async function processError(err: FetchError) {
    console.log('[APIClient] Error occured during fetch:')
    console.log(err.message)

    if (!(err instanceof FetchError)) throw err

    if (err.response) appendCookies(err.response)
    if (!err.data?.error) throw err

    const error = dataToError(err.data as ModelData)
    const { name, code } = error

    if (TOKEN_ERROR_CODES.includes(code)) {
      invalidateAuth()
      return
    }

    // Check if captcha is required
    if (name === 'ValidationError') {
      for (const field of error.data || []) {
        if (field.name === 'captcha') {
          showCaptcha.value = true
        }
      }
    }

    return err
  }

  async function fetch<T>(path: string, options?: FetchOptions) {
    let fetchOpts: any = {}
    showCaptcha.value = false

    if (options) {
      const { auth, ...rest } = options

      if (options.auth && !userState.value.tokenPair) {
        throw new Error('auth required')
      }

      fetchOpts = { ...rest }
    }

    fetchOpts.headers ||= {}
    fetchOpts.headers['X-API-Region'] = region
    if (captchaPayload.value) {
      fetchOpts.headers['Captcha-Payload'] = captchaPayload.value
    }
    
    if (i18n.locale.value != 'en') {
      fetchOpts.headers['Accept-Language'] = i18n.locale.value
    }

    if (!fetchOpts.headers?.Authorization) {
      try {
        await refreshToken()
        fetchOpts.headers = { ...fetchOpts.headers, ...authHeaders() }
      } catch (err) {
        return null
      }
    }
    fetchOpts.headers = { 'X-App-Type': 'pz-site', ...fetchOpts.headers }

    try {
      const res = await $fetch.raw<T>(`${baseUrl}/${path}`, fetchOpts)
      appendCookies(res)
      return res._data
    } catch (err) {
      const error = await processError(err as FetchError)
      if (error) throw error
    } finally {
      captchaPayload.value = ''
    }
  }

  return {
    fetch,
    showCaptcha,
    captchaPayload,
    setFormErrors<T, K extends keyof T>(
      setFieldError: (field: K, message: string | string[] | undefined) => void,
      scope: string, 
      error: NuxtError) {
      const err = dataToError(error.data as ModelData)

      if (err.name !== 'ValidationError') return

      if (err.code) {
        setFieldError('baseError', i18n.t(`${scope}.errors.${err.code}`))
      }
  
      for (const field of err.data || []) {
        setFieldError(
          field.name, i18n.t(`${scope}.errors.${field.name}_${field.code}`)
        )
      }
    },
    async shouldCaptcha(check: string) {
      const result = await fetch<ModelData>(`captcha/${check}`)
      if (!result) return false

      return result.captcha === true
    },
    async cart(id: number|string, opts?: FetchOptions) {
      const result = await useAsyncData(`carts/${id}`, () => {
        return fetch<ModelData>(`carts/${id}`, { auth: true, ...opts })
      })

      const cart = computed(() => {
        if (!result.data.value) return
        return modelMapper.Cart(result.data.value)
      })

      return { ...result, cart }
    },
    async cities() {
      const result = await useAsyncData('cities', () => {
        return fetch<ModelData[]>('cities')
      })

      const cities = computed(() => {
        if (!result.data.value) return
        return result.data.value.map(modelMapper.City)
      })

      return { ...result, cities }
    },
    async stats() {
      const result = await useAsyncData('site/v1/stats', () => {
        return fetch<ModelData>('site/v1/stats')
      })

      const stats = computed<SiteStats|undefined>(() => {
        if (!result.data.value) return undefined
        return result.data.value as SiteStats
      })

      return { ...result, stats }
    },
    async orders() {
      const result = await useAsyncData('orders', () => {
        return fetch<ModelData[]>('orders', { auth: true })
      })

      const orders = computed(() => {
        if (!result.data.value) return
        return result.data.value.map(modelMapper.Order)
      })

      return { ...result, orders }
    },
    async order(id: number) {
      const result = await useAsyncData(`orders/${id}`, () => {
        return fetch<ModelData>(`orders/${id}`, { auth: true })
      })

      const order = computed(() => {
        if (!result.data.value) return
        return modelMapper.Order(result.data.value)
      })

      return { ...result, order }
    },
    async paymentMethods() {
      const result = await useAsyncData('paymentMethods', () => {
        return fetch<ModelData[]>('payment_methods', { auth: true })
      })

      const paymentMethods = computed(() => {
        if (!result.data.value) return
        return result.data.value.map(modelMapper.PaymentMethod)
      })

      return { ...result, paymentMethods }
    },
    async plans() {
      const result = await useAsyncData('plans', () => {
        return fetch<ModelData[]>('plans')
      })

      const plans = computed<Plan[]|undefined>(() => {
        if (!result.data.value) return
        return result.data.value.map(modelMapper.Plan)
      })

      return { ...result, plans }
    },
    async post(code: string) {
      const result = await useAsyncData(`posts/${code}`, () => {
        return fetch<ModelData>(`posts/${code}`)
      })

      const post = computed(() => {
        if (!result.data.value) return
        return modelMapper.Post(result.data.value)
      })

      return { ...result, post }
    },
    async servers() {
      const result = await useAsyncData('servers', () => {
        return fetch<ModelData[]>('servers')
      })

      const servers = computed(() => {
        if (!result.data.value) return
        return result.data.value.map(modelMapper.Server)
      })

      return { ...result, servers}
    },
    async sponsorship() {
      const result = await useAsyncData('sponsorship', () => {
        return fetch<ModelData>('site/v1/sponsorship', { auth: true })
      })

      const sponsorship = computed(() => {
        if (!result.data.value) return
        return modelMapper.Sponsorship(result.data.value)
      })

      return { ...result, sponsorship}
    },
    async subscription() {
      const result = await useAsyncData('subscription', () => {
        return fetch<ModelData>('subscription', { auth: true })
      })

      const subscription = computed(() => {
        if (!result.data.value) return
        return modelMapper.Subscription(result.data.value)
      })

      return { ...result, subscription }
    },
    async user() {
      const result = await useAsyncData('user', () => {
        return fetch<ModelData>('users', { auth: true })
      })
      
      const user = computed(() => {
        if (!result.data.value) return
        return modelMapper.User(result.data.value)
      })

      return { ...result, user }
    },
    async versions() {
      const result = await useAsyncData('versions', () => {
        return fetch<ModelData[]>('versions?channel=all&tags=site')
      })

      const versions = computed<Version[]>(() => {
        if (!result.data.value) return []
        return result.data.value.map(versionMapper)
      })

      return { ...result, versions }
    }
  }
}

export { useAPIClient }
