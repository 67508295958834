import { dataToBase, type Base, type ModelData } from './base'

interface Plan {
  id: number
  name: string
  code: string
  tier: number
  products: Product[]
  description: { features?: string[] }
  pricing?: {
    monthly: PlanPricing,
    yearly: PlanPricing
  }
}

interface PlanPricing {
  total: Money
  byMonth: Money
}

interface Order extends Base {
  status: string
  paymentMethod: string
  cart: Cart
  reference: string
  refunded: boolean
  error?: string
}

interface Cart extends Base {
  kind: string
  lineItems: LineItem[]
  totalPrice: Money
  creditApplied?: Money
  bound: boolean
  orderId?: number,
  paid: boolean,
  adjustments: CartAdjustment[]
  metadata?: {
    paypalOrderId: string
    stripePaymentIntent: string
    stripeClientSecret: string
  }
}

interface CartAdjustment {
  kind: string
  description: string
  amount: Money
}

interface LineItemMetadata {
  productFrom?: number
  productTo?: number
  proratedDays?: number
}

interface LineItem extends Base {
  quantity: number
  product?: Product
  price: Money
  adjustment?: Money
  metadata?: LineItemMetadata
}

interface Product extends Base {
  name: string
  description: string
  code?: string
  kind?: string
  planId?: number
  price: Money
  durationCount?: number,
  durationUnit?: string,
  metadata: { paypalPlanId?: string }
}

interface Voucher extends Base {
  code: string
  redeemed: boolean
  redeemerId?: number
}

function dataToPlan (data: any) {
  const plan: Plan = {
    id: data.id,
    name: data.name,
    code: data.code,
    tier: data.tier,
    products: [],
    description: data.description
  }

  if (data.products) {
    plan.products = (data.products as any[]).map(d => dataToProduct(d))
  }

  const productMonthly = plan.products.find((p) => {
    return p.durationCount === 1 && p.durationUnit === 'month'
  })

  const productYearly = plan.products.find((p) => {
    return p.durationCount === 12 && p.durationUnit === 'month'
  })

  if (!productMonthly || !productYearly) return plan

  plan.pricing = {
    monthly: {
      total: productMonthly?.price,
      byMonth: productMonthly.price
    },
    yearly: {
      total: productYearly?.price,
      byMonth: dataToMoney({
        amount: (productYearly.price.amount / 12).toFixed(0),
        currency: productYearly.price.currency
      }) 
    }
  }

  return plan
}

function dataToOrder(data: ModelData): Order {
  const order: Order = {
    ...dataToBase(data),
    status: data.status,
    paymentMethod: data.paymentMethod,
    cart: dataToCart(data.cart),
    reference: data.reference,
    refunded: data.refunded,
    error: data.error
  }
  return order
}

function dataToCart(data: ModelData): Cart {
  const cart: Cart = {
    ...dataToBase(data),
    kind: data.kind,
    bound: data.bound,
    lineItems: data.lineItems.map((li: ModelData) => dataToLineItem(li)),
    totalPrice: dataToMoney(data.totalPrice),
    paid: data.paid,
    orderId: data.orderId,
    adjustments: (data.adjustments || []).map((adata: ModelData) => {
      return {
        kind: adata.kind,
        description: adata.description,
        amount: dataToMoney(adata.amount)
      }
    })
  }

  if (data.creditApplied) cart.creditApplied = dataToMoney(data.creditApplied)
  if (data.metadata) cart.metadata = data.metadata
  return cart
}

function dataToLineItem(data: ModelData): LineItem {
  const lineItem: LineItem = {
    ...dataToBase(data),
    quantity: data.quantity,
    product: dataToProduct(data.product),
    price: dataToMoney(data.price),
    metadata: data.metadata
  }

  if (data.adjustment) {
    lineItem.adjustment = dataToMoney(data.adjustment)
  }

  return lineItem
}

function dataToProduct(data: ModelData): Product {
  const product: Product = {
    ...dataToBase(data),
    name: data.name,
    description: data.description,
    code: data.code,
    kind: data.kind,
    price: dataToMoney(data.price),
    durationCount: data.durationCount,
    durationUnit: data.durationUnit,
    metadata: data.metadata
  }

  if (data.planId) {
    product.planId = data.planId
  }

  return product
}

function dataToVoucher(data: ModelData): Voucher {
  const voucher: Voucher = {
    ...dataToBase(data),
    code: data.code,
    redeemed: data.redeemed,
    redeemerId: data.redeemerId
  }

  return voucher
}

interface Money {
  amount: number
  currency: string
  symbol(): string
  value(): number
  format(): string
}

function dataToMoney (data: any) {
  const obj: Money = {
    amount: data.amount,
    currency: data.currency,
    symbol() {
      if (this.currency === 'CNY') return '¥'
      return '$'
    },
    value() {
      return (this.amount / 100)
    },
    format() {
      return `${this.symbol()}${this.value().toFixed(2)}`
    }
  }
  return obj
}

export type { Cart, LineItem, Money, Order, Plan, Product }
export {
  dataToCart,
  dataToLineItem,
  dataToMoney,
  dataToOrder,
  dataToPlan,
  dataToProduct,
  dataToVoucher
}
