import { dataToBase, type Base, type ModelData } from './base'
import { DateTime } from 'luxon'

interface Post extends Base {
  title: string
  content: string
  postedAt: DateTime
}

function dataToPost(data: ModelData) {
  const post: Post = {
    ...dataToBase(data),
    title: data.title,
    content: data.content,
    postedAt: DateTime.fromISO(data.postedAt)
  }

  return post
}

export { dataToPost }
export type { Post }
