import type { ModelData } from './base'

interface Sponsorship {
  signUps: number
  conversionsTrial: number
  conversionsPaid: number
}

function dataToSponsorship (data: ModelData): Sponsorship {
  const resource: Sponsorship = {
    signUps: data.signUps,
    conversionsTrial: data.conversionsTrial,
    conversionsPaid: data.conversionsPaid
  }

  return resource
}

export { dataToSponsorship }
export type { Sponsorship }
