import { dataToBase } from './base'
import type { Base, ModelData } from './base'

import { dataToError, APIError } from './errors'
import type { FieldErrors } from './errors'

import { dataToApp } from './appGame'
import { dataToCity, dataToServer } from './server'
import {
  dataToPaymentMethod,
  dataToSubscription,
  dataToTokenPair,
  dataToUser,
} from './user'
import {
  dataToCart,
  dataToMoney,
  dataToOrder,
  dataToPlan,
  dataToProduct,
  dataToVoucher
} from './order'
import { dataToSponsorship } from './sponsorship'
import { dataToPost } from './post'
import type { ActionResponse } from './response'

const modelMapper = {
  Base: dataToBase,
  App: dataToApp,
  Cart: dataToCart,
  City: dataToCity,
  Money: dataToMoney,
  Order: dataToOrder,
  PaymentMethod: dataToPaymentMethod,
  Post: dataToPost,
  Product: dataToProduct,
  Plan: dataToPlan,
  Server: dataToServer,
  Sponsorship: dataToSponsorship,
  Subscription: dataToSubscription,
  TokenPair: dataToTokenPair,
  User: dataToUser,
  Voucher: dataToVoucher,
  APIError: dataToError
}

export type { ModelData, FieldErrors, Base, ActionResponse }
export {
  dataToError,
  dataToBase,
  modelMapper,
  APIError
}
