import type { ModelData } from './base'

interface City {
  id: number
  name: string
  code: string
  country: string
  countryCode: string
  location?: { lat: number, lon: number }
}
interface Server {
  id: number
  name: string
  hostname: string
  online: boolean
  tier: number
  cityId: number
  city: City
  load: number
  tags?: string[]
}

function dataToCity(data: ModelData) {
  const { id, name, code, country, countryCode, location } = data
  const city: City = {
    id,
    name,
    code,
    country,
    countryCode,
    location
  }
  return city
}

function dataToServer (data: any): Server {
  const { id, name, hostname, cityId, city, online, tier, load, tags } = data
  const server: Server = {
    id,
    name,
    hostname,
    cityId,
    city: dataToCity(city),
    online,
    tier,
    load
  }

  if (tags) server.tags = tags

  return server
}

export type { City, Server }
export { dataToCity, dataToServer }
