type AppKinds = 'app' | 'game'

interface AppGame {
  id: number,
  name: string,
  kind: AppKinds
  versions: AppVersion[]
}

interface AppVersion {
  uuid: string
  default: boolean
  image: string
}

function dataToApp (data: any) {
  const versions = data.versions.map((d: any) => datatoAppVersion(d))

  const app: AppGame = {
    id: data?.id,
    name: data?.name,
    kind: data?.kind,
    versions
  }

  return app
}

function datatoAppVersion (data: any) {
  const version = {
    uuid: data.uuid,
    default: data.default,
    image: data.image
  }

  return version
}

export type { AppGame, AppKinds, AppVersion }
export { dataToApp, datatoAppVersion }
