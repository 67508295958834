import type { ModelData } from './base'

class APIError extends Error {
  name: string
  code: string
  message: string
  data: any

  constructor(name: string, code: string, message: string, data: any) {
    super(message)

    this.name = name
    this.message = message
    this.code = code || ''
    this.data = data
  }
}

function dataToError(data: ModelData) {
  const { error: errData } = data
  const error = new APIError(
    errData.name, errData.code, errData.message, errData.data
  )
  return error
}

interface FieldErrors {
  [key: string]: string[]
}

export  {
  APIError,
  dataToError
}

export type { FieldErrors }
