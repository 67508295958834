import { DateTime } from 'luxon'
import { dataToBase, type ModelData, type Base } from './base'

import { dataToPlan, dataToProduct, dataToMoney } from './order'
import type { Plan, Product, Money } from './order'

interface Subscription extends Base {
  status: string
  nextBillAt?: DateTime
  nextCycleAt?: DateTime
  nextCycleLocked: boolean,
  plan: Plan
  product: Product
  trial: boolean
  canceling: boolean
  updating: boolean
  paymentMethod?: PaymentMethod,
  nextProduct?: Product,
  notice: number,
  renewalOrderId?: number,
  meta: {
    promos: string[]
  },
  metadata: {
    paypalSubscriptionId?: string,
    stripeClientSecret?: string,
  }
}

function dataToSubscription(data?: ModelData) {
  if (!data) return undefined

  const sub: Subscription = {
    id: data.id,
    status: data.status,
    nextBillAt: data.nextBillAt ? DateTime.fromISO(data.nextBillAt) : undefined,
    nextCycleAt: data.nextCycleAt ? DateTime.fromISO(data.nextCycleAt) : undefined,
    nextCycleLocked: data.nextCycleLocked,
    trial: data.trial,
    canceling: data.canceling,
    updating: data.updating,
    plan: dataToPlan(data.plan),
    product: dataToProduct(data.product),
    notice: data.notice,
    renewalOrderId: data.renewalOrderId,
    meta: data.meta,
    metadata: data.metadata
  }

  if (data.paymentMethod) {
    sub.paymentMethod = dataToPaymentMethod(data.paymentMethod)
  }

  if (data.nextProduct) {
    sub.nextProduct = dataToProduct(data.nextProduct)
  }

  return sub
}

interface User extends Base {
  username?: string
  email?: string
  newEmail?: string
  tokenPair?: TokenPair
  confirmedAt?: DateTime
  subscription?: Subscription
  credit?: Money
  info: {
    region?: string
  },
  settings: {
    locale?: string
  }
}

function dataToUser(data: ModelData): User {
  const user: User = {
    ...dataToBase(data),
    username: data.username,
    email: data.email,
    newEmail: data.newEmail,
    info: data.info,
    settings: data.settings,
  }

  if (data.confirmedAt) user.confirmedAt = DateTime.fromISO(data.confirmedAt)
  if (data.tokenPair) user.tokenPair = dataToTokenPair(data.tokenPair)

  if (data.subscription) {
    user.subscription = dataToSubscription(data.subscription)
  }

  if (data.credit) {
    user.credit = dataToMoney(data.credit)
  }

  return user
}

interface TokenPair {
  accessToken: string
  accessExpiry: number
  refreshToken: string
  refreshExpiry: number
  userId: number
  region?: string
}

function dataToTokenPair(data: any): TokenPair {
  const tp: TokenPair = {
    accessToken: data.accessToken,
    accessExpiry: data.accessExpiry,
    refreshToken: data.refreshToken,
    refreshExpiry: data.refreshExpiry,
    userId: data.userId,
    region: data.region
  }
  return tp
}

interface CardData {
  brand: string
  last4: string
  expMonth: number
  expYear: number
}

interface PaypalData {
  email: string
}

interface PaymentMethod extends Base {
  active: boolean,
  kind: string,
  card?: CardData
  paypal?: PaypalData
}

function dataToPaymentMethod(data: any): PaymentMethod {
  const pm: PaymentMethod = {
    ...dataToBase(data),
    kind: data.kind,
    active: data.active,
  }

  if (data.card) pm.card = data.card
  return pm
}

export type { User, PaymentMethod, Subscription, TokenPair }
export {
  dataToPaymentMethod,
  dataToSubscription,
  dataToTokenPair,
  dataToUser
}
