import { DateTime } from 'luxon'
import { dataToBase, type Base, type ModelData } from './base'

interface Version extends Base {
  version: string
  versionDate: DateTime
  channel: string
  downloadLink: string
  releaseNotes: string
}

function versionMapper(data: ModelData): Version {
  const version = {
    ...dataToBase(data),
    version: data.version,
    versionDate: DateTime.fromISO(data.versionDate),
    channel: data.channel,
    downloadLink: data.downloadLink,
    releaseNotes: data.releaseNotes
  }

  return version
}

export { versionMapper }
export type { Version }
